import React from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { StaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import MainLayout from '../layouts/mainLayout';
import { BlogPostList } from 'services/models/blog';

const BlogPosts = loadable(() => import('components/PrismicBlogPosts'));

const mainBlogMetaData = {
  title: 'Blog for Dog Food. Feed My furbaby New Zealand',
  description:
    'Read our feed fur baby blog to stay updated with our award winning posts that offers the best solutions for all your dog food related queries.',
};

export default () => (
  <MainLayout
    withTitle={true}
    title={mainBlogMetaData.title}
    description={mainBlogMetaData.description}
  >
    <div className="ingr-col-wrapper no-mgn-bot blog-wrap-or">
      <div className="container-fluid">
        <div className="def-w-max">
          <div className="ing-hdr-wrap text-center">
            <h2 className="mgn-bot-20">Blogs</h2>
          </div>
          <div className="blog-contents">
            <div className="row">
              <StaticQuery
                query={`${blogHomeQuery}`}
                render={data => {
                  const posts: BlogPostList[] = data.allPrismicBlogPost.edges;
                  let orderedPosts = posts.map(item => {
                    item.timestamp = moment(item.node.data.timestamp).unix();
                    return item;
                  });
                  orderedPosts = [
                    ...orderBy(
                      orderedPosts.filter(item => item.node.data.priority),
                      'timestamp',
                      'desc',
                    ),
                    ...orderBy(
                      orderedPosts.filter(item => !item.node.data.priority),
                      'timestamp',
                      'desc',
                    ),
                  ];
                  return <BlogPosts posts={orderedPosts} />;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
);

const blogHomeQuery = graphql`
  {
    allPrismicBlogPost {
      edges {
        node {
          id
          uid
          data {
            title {
              text
              html
              raw
            }
            tags
            previewtext
            timestamp
            body {
              ... on PrismicBlogPostBodyText {
                primary {
                  text {
                    text
                    html
                    raw
                  }
                }
              }
              ... on PrismicBlogPostBodyImageWithCaption {
                primary {
                  header_im {
                    url
                    alt
                  }
                }
              }
            }
            priority
          }
        }
      }
    }
  }
`;
